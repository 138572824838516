<template>
  <div class="chart row-between column">
    <!--<van-swipe
        vertical
        class="chartSwiper"
        :touchable="false"
        :autoplay="level == 0 ? 3000 : false"
        :show-indicators="false">
      <van-swipe-item><div class="buleKey1">请记住 https://www.wph3.cn 方便随时使用</div></van-swipe-item>
    </van-swipe>-->

    <!--<div class="topBar" style="display: flex; align-items: center;padding:5px 10px;background-color: #fff;">
      <div class="topNotice" style="border-radius: 5px; flex: 1; text-align: left; ">
        <a class="buleKey1">收藏本站地址，防丢失不迷路</a>
      </div>
      <div class="shortcut" style="margin-left: auto; border-radius: 5px; padding: 3px 5px;background: #16a37f; color:#fff;" @click="goShortcut">生成快捷方式</div>
    </div>-->

    <div style="background: #16a37f;padding:10px;color:#fff;">
      <span style="display: block;width:10%;display: block;float: left;" @click="$router.go(-1)">返回</span>
      <span style="text-align: center;width: 90%;display: block;font-size: 0.16rem;">{{ $route.params.title }}</span>
    </div>

    <div class="queryChatMain flex1" @scroll="orderScroll">
      <div
          v-for="(item, index) in queryChatList"
          class="queryChatListLi"
          :key="item.id">
        <div class="timeChart" v-if="index == 0 || (index + 1) % 5 == 1">{{item.createtime}}</div>
        <div class="row queryChatListLiLine end row-end" v-if="item?.userQuestion">
          <div class="queryChatListText buleKeyBack userQuestion">
            {{item.userQuestion}}
          </div>
          <div class="userHeadImg" />
        </div>
        <div class=" row row-start  queryChatListLiLine start" v-if="item?.answer">
          <div class="icon-Ai"/>
          <div class="queryChatListText answer align-center row" :class="item.statusAni" v-if="item.statusAni != 'noResidualDegree'" style="display: flex; flex-direction: column; align-items: flex-start;">
            <span style="whiteSpace: pre-line;">{{item.answer}}</span>
            <span :class="item.statusAni"></span>
            <button class="copy-btn" @click="copyAnswer(item.answer)" style="margin-top: 5px;">复制</button>
          </div>
          <div class="queryChatListText answer align-center row" :class="item.statusAni" v-else>
            <span v-if="item.isLogin" style="font-size: 0.2rem;">解锁无限提问次数，请<b @click="$router.push('/home')" class="colorRed pointerCursor">开通会员</b></span>
            <span v-if="!item.isLogin">点击右下角【我的】登录后继续使用</span>
            <span :class="item.statusAni"></span>
          </div>
        </div>

        <div class="examplesDiv" v-if="item?.examples">
          <div style="font-size:0.18rem;text-align: center;">使用示例</div>
          <div>
            <div v-for="item in item.examples" :key="item" class="example" @click="copySpanText">
              <span>{{ item }}</span>
              <span>→</span>
            </div>
          </div>
          <!--<div style="text-align: center;background: #16a37f;border-radius:5px;padding:2%;"><a style="color: #fff;font-size:0.18rem" href="http://www.wph6.cn" target="_blank">点我立即体验助手AI在线起名</a></div>-->
        </div>

      </div>

    </div>
    <div class="chartBottom row-between align-center">
      <van-cell-group inset class="flex1">
        <van-field
            v-model="message"
            rows="1"
            autosize
            label=""
            type="textarea"
            :placeholder="placeholder"
            @focus="changeChartTop"
        >
        </van-field>
      </van-cell-group>
      <div class="applyButton align-center row-center" @click="changeChartPush">
        <div class="iconfont icon-fasong"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent, onMounted, watchEffect, nextTick, computed } from 'vue'
import { useRoute } from 'vue-router'
import { queryChatRecord, sendMessage } from '@/api/chat'
import { useStore } from 'vuex'
import { showNotify } from 'vant'
import ClipboardJS from 'clipboard';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { getToken } from '@/utils/auth'
import { encode, decode } from 'urlencode'

const clipboard = new ClipboardJS('.copy-btn', {
  text(trigger) {
    const answer = trigger.parentNode.querySelector('span').innerHTML;
    return answer;
  }
});
clipboard.on('success', () => {
  alert('复制成功');
});
clipboard.on('error', () => {
  alert('复制失败，请手动复制');
});

export default defineComponent({
  name: 'chart',
  setup () {
    const data = reactive([
      { ref: 'efficiency', index: 0, examples: ['我是一名程序员，今天用 Python 开发了一套 Devops 平台', '今天约见了3位客户，讲解社交APP是如何使用的', '今天开了一天会议，帮我写一份日报'], prompt: '请输入您的今日工作' },
      { ref: 'efficiency', index: 1, examples: ['我是一名程序员，本周用 Python 开发了一套 Devops 平台', '本周约见了10位客户，讲解社交APP是如何使用的', '本周开了5天会议，帮我写一份周报'], prompt: '请输入您的本周工作' },
      { ref: 'efficiency', index: 2, examples: ['电脑桌上拍摄手办，体现产品 LOGO', '废墟拍摄，美女拿刀的帅气形象', '女孩骑自行车，要求有跟拍镜头'], prompt: '请输入您未来的任务' },
      { ref: 'efficiency', index: 3, examples: ['北京3日游，有老人腿脚不方便，没有私家车，只能公交地铁，告诉我如何前往', '云南大理7日游', '泰国5日游，我想去非常值得去的地方'], prompt: '请输入您的旅游要求' },
      { ref: 'marketing', index: 0, examples: ['咖啡店打卡，集美们冲啊', '小白该如何学会给女朋友拍照', '一个月瘦20斤，我是如何办到的？'], prompt: '请输入您想在小红书发布的内容' },
      { ref: 'marketing', index: 1, examples: ['北京这家烧烤店，包含羊肉串、牛肉串、烤翅、大腰子、牛板筋有多好吃', '怀柔民宿，环境优美，空气清晰，介绍都有哪些好', '北京这家足疗店，技师按摩手法是多么厉害'], prompt: '请输入您想点评的内容' },
      { ref: 'marketing', index: 2, examples: ['评价下 Switch 游戏机是多么好玩', '评价下这台冰箱在家里是多么好用', '评价下这件衣服，有多么漂亮'], prompt: '请输入您想点评的内容' },
      { ref: 'marketing', index: 3, examples: ['美食节特别优惠', '生活方式', '限时折扣'], prompt: '请输入您的内容' },
      { ref: 'entertainment', index: 0, examples: ['因为晚上没有给女朋友做饭，怎么能把我女朋友哄开心？', '早上没有叫女朋友起床，耽误了女朋友错过班车，怎么能哄她开心？', '情人节忘记给女朋友发 520 红包了，怎么能让女朋友不因为这件事情生气？'], prompt: '请输入您是如何惹您女朋友生气的？' },
      { ref: 'entertainment', index: 1, examples: ['约了妹子晚上去吃饭，我该如何做，才能让她对我有好感？', '今天遇到个妹子，特别好看，一见钟情，我如何才能认识她？', '公司新来了个女同事，我心动了怎么办？'], prompt: '请输入您的当前情势' },
      { ref: 'entertainment', index: 2, examples: ['女朋友过生日', '朋友买了房子', '今天中国的春节，祝福亲戚朋友'], prompt: '请输入您的祝福内容' },
      { ref: 'entertainment', index: 3, examples: ['今天梦到了去河里游泳，然后一直上不了岸', '梦到了去三亚，在酒店住了很多天，一直醒不来', '梦见走到马路上，捡到了个1000元。'], prompt: '请输入您的梦境' },
      { ref: 'entertainment', index: 4, examples: ['借我200元', '今天合同不签了', '没有计划合作'], prompt: '请输入对方的问题' },
      { ref: 'entertainment', index: 5, examples: ['我没有按时完成任务', '我造成了1万块钱的损失', '我写了个 BUG'], prompt: '请输入您的问题' },
      { ref: 'entertainment', index: 6, examples: ['这个小学生真棒', '朋友你写代码真厉害', '同事工作能力好厉害'], prompt: '请输入您的内容' },
      { ref: 'entertainment', index: 7, examples: ['你怎么还没有女朋友？', '你怎么这么二', '不上班？怎么办'], prompt: '请输入您的内容' },
    ]);
    const route = useRoute()
    const placeholder = data.find(item => item.ref == route.params.ref && item.index == route.params.index)?.prompt;
    const store = useStore()
    const state = reactive({
      message: '',
      queryChatList: [],
      queryChatListCard: [],
      residualDegree:  computed(() => store.getters.residualDegree),
      level: computed(() => store.getters.roles),
      isSendIng: false,
      pageNum: 1,
      count: 0,
      loading: false,
      isLastPage: false,
      bscroll: ''
    })
    const methods = reactive({
      goShortcut() {
        const ua = navigator.userAgent.toLowerCase();
        if (/iphone|ipad|ipod/.test(ua)) {
          this.$router.push('/shortcut_ios');
        } else if (/android/.test(ua)) {
          this.$router.push('/shortcut_android');
        } else {
          this.$router.push('/shortcut_windows');
        }
      },
      copySpanText(event) {
        var clickedElement = event.target;
        var divElement = clickedElement.closest('.example');
        var firstSpan = divElement.querySelector('span:first-child');
        var text = firstSpan.textContent;
        state.message = text;
      },
      //粘贴回答
      copyAnswer(answer) {
        const btn = document.querySelector('.copy-btn');
      },
      // 查询历史数据
      loadingHistory (resolve) {
        const params = {
          pageSize: 10,
          pageNum: state.pageNum
        }
        queryChatRecord(params).then((res) => {
          return resolve(res)
        })
      },
      // 改变信息到最后一条
      changeChartTop (id) {
        const parent = document.querySelector('.queryChatMain')
        parent.scrollTo({
          top: parent.scrollHeight, // 需要父元素设置postion(relative、absolute、fixed)
          behavior: 'smooth'
        })
      },
      // 发送信息
      changeChartPush () {
        if (state.message != '' && !state.isSendIng) {
          state.isSendIng = true
          const notify = {
            'loading':'正在输入',
            'noResidualDegree': '您的提问次数已用完，想要继续体验，请开通会员'
          }
          const status = state.residualDegree != 0 ? 'loading' : 'noResidualDegree'
          const isLogin = store.getters.isLogin == 0 ? false : true;
          state.queryChatList.push({
            userQuestion: state.message,
            addId: `add${state.queryChatList.length}`,
            answer: notify[status],
            statusAni: status,
            isLogin: isLogin
          })
          const message = state.message
          state.message = ''
          setTimeout(() => {
            methods.changeChartTop()
          }, 5)
          //没有次数
          if (state.residualDegree != 0) {
            if (typeof (EventSource) !== 'undefined') {
              var msg = ''
              const evtSource = new EventSourcePolyfill(
                  '/api/chat/sendGroupMessage?message='+encode(message, 'utf8')
                  + '&group=' + route.params.ref
                  + '&number=' +  route.params.index,
                  {
                withCredentials: true,
                headers: {
                  'access_token' : getToken(),
                  'content-type' : 'application/x-www-form-urlencoded'
                }
              })
              evtSource.addEventListener('message', function(e) {
                const dataObj = JSON.parse(e.data)
                var code = dataObj.code
                var id = dataObj.id
                var userId = dataObj.userId
                var userLevel = dataObj.userLevel
                var userQuestion = dataObj.userQuestion
                var answer = dataObj.answer
                var createtime = dataObj.createtime
                var updatetime = dataObj.updatetime
                var isLimit = dataObj.isLimit
                var deleted = dataObj.deleted
                if(code != 0){
                  msg = msg+answer
                  const myJson = {
                    id: id,
                    userId: userId,
                    userLevel: userLevel,
                    userQuestion: userQuestion,
                    answer: msg,
                    createtime: createtime,
                    updatetime: updatetime,
                    isLimit: isLimit,
                    deleted: deleted
                  };
                  state.queryChatList[state.queryChatList.length - 1] = myJson
                  setTimeout(() => {
                    methods.changeChartTop()
                  }, 5)
                  if (code != 6){
                    evtSource.close();
                    state.isSendIng = false
                    setTimeout(() => {
                      methods.changeChartTop()
                    }, 5)
                  }
                }else{
                  state.isSendIng = false
                  if (state.level == 0) {
                    store.dispatch('GetInfo').then(() => {})
                  }
                  setTimeout(() => {
                    methods.changeChartTop()
                  }, 5)
                  evtSource.close();
                }
              })
              evtSource.onerror = function(e){
                state.isSendIng = false
                state.queryChatList[state.queryChatList.length - 1].answer = '请稍后在试'
                setTimeout(() => {
                  methods.changeChartTop()
                }, 5)
                console.log(e);
                evtSource.close(); // 关闭连接
              }
            } else {
              console.log('当前浏览器不支持使用EventSource接收服务器推送事件!');
            }
          }
        } else {
          if(state.message == '') {
            return showNotify({ type: 'warning', message: '发送信息不能为空' })
          } else {
            return showNotify({ type: 'warning', message: '请等待前面的回答完成，同时不能有两个提问' })
          }

        }
      },
      // 插入数据到列表
      loadingData (isTop) {
        new Promise((resolve, reject) => {
          methods.loadingHistory(resolve)
        }).then((res) => {
          state.queryChatList.unshift(...res.data.list)
          state.loading = false
          // 是否为最后一页
          state.isLastPage = res.data.isLastPage
          if (isTop) {
            setTimeout(() => {
              methods.changeChartTop()
            }, 5)
          }
        })
      },
      // 下拉刷新
      onRefresh () {
        // 加载状态结束
        if (!state.isLastPage) {
          state.pageNum = state.pageNum + 1
          methods.loadingData()
        } else {
          state.loading = false
        }
      },
      orderScroll (e) {
        const conScrollTop = e.target.scrollTop//  内容滚动了的高度
        // 内容滚动了的高度 +  区域内容的高度 >= 可以滚动区域的高度
        // 则证明滑动到了页面底部，这个时候就去处理加载更多的逻辑
        // 加载状态结束
        if (!state.isLastPage && conScrollTop <= 0) {
          state.pageNum = state.pageNum + 1
          methods.loadingData()
        } else {
          state.loading = false
        }
      }

    })
    onMounted(() => {
      const efficiencyExamples = data.find(item => item.ref == route.params.ref && item.index == route.params.index)?.examples;
      methods.loadingData(true)
      state.queryChatList.push({
        answer: route.params.description,
        addId: `addhuanying`
      })
      state.queryChatList.push({
        examples: efficiencyExamples,
        addId: `addexamples`
      })
    })
    watchEffect(() => {
      nextTick(() => {
        state.bscroll && state.bscroll.refresh()
      })
    })
    return {
      ...toRefs(state),
      ...toRefs(methods),
      placeholder
    }
  }
})
</script>

<style scoped lang="less">
@import "./common";
.copy-btn {
  margin-left: 0px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

</style>
